import '@fontsource/roboto-mono';
import '@fontsource/spectral';
import '@fontsource/spectral/300.css';
import '@fontsource/spectral/400.css';
import '@fontsource/spectral/800.css';
import '@fontsource/spectral/300-italic.css';
import '@fontsource/spectral/400-italic.css';
import '@fontsource/spectral/800-italic.css';
import 'prismjs/themes/prism.css';
import './src/styles/fonts.css';
import './src/styles/styles.css';
require("prismjs/plugins/line-numbers/prism-line-numbers.css");

